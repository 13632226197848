<template>
  <div class="page flex-row justify-center align-center">
    <div :class="{'content':true, 'buyContent':buyVipOrderData.status == 0}">
      <div
        v-if="buyVipOrderData.status == 0 && buyVipOrderData.payType == 1"
        class="success-content flex-col justify-center align-center"
      >
        <span class="wxPay-title">微信支付</span>
        <span class="money">￥{{ buyVipOrderData.payAmount }}</span>
        <div
          class="flex-col center-info justify-center align-center"
          style="height: 345rem; margin: 34rem 0 37rem 0"
        >
          <span style="color: #a4a4a4">使用微信「扫一扫」扫码付款</span>
          <img
            v-if="buyVipOrderData.rawUrl"
            :src="'data:image/png;base64,' + buyVipOrderData.rawUrl"
          />
        </div>
        <!-- <div class="otherPay-view flex-col">
          <div class="service flex-row align-center">
            如需企业公对公转账，请
            <txtServicePopover placement="right" serviceTxt="联系我们" />
          </div>
        </div> -->
        <div class="btn-group flex-row">
          <div id="default-btn" class="back" @click="goBack">返回</div>
        </div>
      </div>
      <div
        v-if="buyVipOrderData.status == 0 && buyVipOrderData.payType == 4"
        class="success-content flex-col justify-center align-center"
      >
        <span class="wxPay-title">支付宝支付</span>
        <span class="money">￥{{ buyVipOrderData.payAmount }}</span>
        <div
          class="flex-col center-info justify-center align-center"
          style="height: 345rem; margin: 34rem 0 37rem 0"
        >
          <span style="color: #a4a4a4">使用支付宝「扫一扫」扫码付款</span>
          <img
            v-if="buyVipOrderData.rawUrl"
            :src="'data:image/png;base64,' + buyVipOrderData.rawUrl"
          />
        </div>
        <!-- <div class="otherPay-view flex-col">
          <div class="service flex-row align-center">
            如需企业公对公转账，请
            <txtServicePopover placement="right" serviceTxt="联系我们" />
          </div>
        </div> -->
        <div class="btn-group flex-row">
          <div id="default-btn" class="back" @click="goBack">返回</div>
        </div>
      </div>
      <div
        v-if="buyVipOrderData.status == 1"
        class="success-content flex-col justify-center align-center"
      >
        <div class="pay-icon flex-col justify-center align-center">
          <img src="@/assets/icon/tick-circle.png" alt="" />
          <span class="success">付款成功，感谢您</span>
        </div>
        <div class="desc flex-row align-center">
          <span>{{ num }}</span> &nbsp;秒后自动跳转到会员中心页面
        </div>
        <div class="btn-group flex-row">
          <div id="default-btn" class="back" @click="goGold">立即跳转</div>
        </div>
      </div>
      <div
        v-if="buyVipOrderData.status == 2"
        class="cancel-content flex-col justify-center align-center"
      >
        <div class="pay-icon flex-col justify-center align-center">
          <img src="../../assets/icon/loseEfficacy.png" alt="" />
          <span>已失效，请返回重新购买</span>
        </div>
        <div class="btn-group flex-row">
          <div id="default-btn" class="back" @click="goBack">返回</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { getBuyVipOrderInfo } from "@/api/request";
import { Loading } from "element-ui";
// import txtServicePopover from "@/components/txtServicePopover.vue";

export default {
  components: {
    // txtServicePopover,
  },
  data() {
    return {
      buyVipOrderId: null, // 临时订单id
      payTimer: null, // 初始定时器变量名为null
      buyVipOrderData: {},
      loading: null,
      submitStatus: false,
      num: 5,
      timer: null,
    };
  },
  computed: {},
  created() {
    // 微信二维码支付
    if (this.$route.query.buyVipOrderId) {
      this.buyVipOrderId = this.$route.query.buyVipOrderId;
    }
    // 非微信二维码支付（跳转第三方支付，比如支付宝）
    if (this.$route.query.invoiceId) {
      this.buyVipOrderId = this.$route.query.invoiceId;
    }
    this.getBuyVipOrderInfo(this.buyVipOrderId, true);
    this.payTimer = setInterval(() => {
      this.getBuyVipOrderInfo(this.buyVipOrderId);
    }, 5000);
  },
  methods: {
    getBuyVipOrderInfo(buyVipOrderId, isFirst = false) {
      if (isFirst) {
        this.loading = Loading.service({
          lock: true,
          background: "rgba(255, 255, 255, 0.8)",
        });
      }
      getBuyVipOrderInfo({
        buy_vip_order_id: buyVipOrderId,
      })
        .then((res) => {
          if (res.status !== "0") {
            this.clearTimer();
          }
          // 支付成功
          if (res.status == '1') {
            this.num = 5;
            this.timer = null;
            this.timer = setInterval(() => {
              if (this.num == 0) {
                this.goGold();
                return;
              }
              this.num--;
            }, 1000);
          }
          this.buyVipOrderData = res;
          if (isFirst) {
            this.loading.close();
          }
        })
        .catch((code) => {
          if (isFirst) {
            this.loading.close();
          }
          this.clearTimer();
          // 订单不存在等需要跳转404页面的情况
          if (code == "40003" || code == "40004" || code == "10001") {
            this.$router.replace({ path: "/error" });
          } else {
            this.$router.back();
          }
        });
    },
    goBack() {
      this.$router.replace({ name: "ProductPrice" });
    },
    goGold() {
      this.$router.replace({ name: "Gold" });
    },
    clearTimer() {
      if (this.payTimer) {
        clearInterval(this.payTimer);
        this.payTimer = null;
      }
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  },
  beforeDestroy() {
    this.clearTimer();
  },
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  min-height: calc(100vh - 233rem);
  background: #f8f8f8;
  .content {
    width: 880rem;
    background-color: #fff;
    padding: 65rem 0 50rem 0;
    // padding: 143rem 0 115rem 0;
    border-radius: 6rem;
    .success-content,
    .cancel-content {
      width: 100%;
      .wxPay-title {
        font-size: 28rem;
        color: #272927;
        line-height: 40rem;
      }
      .money {
        font-size: 28rem;
        color: #2bb3e3;
        line-height: 40rem;
        position: relative;
        top: 10rem;
      }
      .pay-icon {
        img {
          width: 70rem;
          height: 70rem;
        }
        span {
          font-size: 28rem;
          color: #F52F3E;
          line-height: 40rem;
          margin-top: 23rem;
        }
        .success {
          color: #2bb3e3;
        }
      }
      .desc {
        margin-top: 28rem;
        font-size: 18rem;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #252927;
        line-height: 25rem;
        span{
          color: #2BB3E3;
        }
      }
      .center-info {
        background-color: #f8f8f8;
        width: 627rem;
        height: 138rem;
        margin: 42rem 0 40rem 0;
        border-radius: 12rem;
        span,
        .txtServicePopover {
          font-size: 18rem;
          color: #555755;
          line-height: 25rem;
        }
        span:nth-child(2),
        .txtServicePopover {
          margin-top: 8rem;
        }
        img {
          width: 220rem;
          height: 220rem;
          margin-top: 32rem;
        }
      }
      .overstep {
        background-color: #f8f8f8;
        width: 627rem;
        height: 138rem;
        margin: 42rem 0 40rem 0;
        border-radius: 12rem;
        img{
          width: 100rem;
          height: 100rem;
          margin-bottom: 37rem;
        }
        span{
          font-size: 18rem;
          color: #555755;
          line-height: 25rem;
          margin-top: 3rem;
        }
      }
      .otherPay-view {
        width: 627rem;
        .pay-titlt {
          height: 18rem;
          line-height: 18rem;
          margin-bottom: 28rem;
          .border-left {
            width: 3rem;
            height: 18rem;
            background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
          }
          .title-txt {
            font-size: 18rem;
            color: #252927;
            line-height: 25rem;
            margin-left: 14rem;
          }
        }
        .service {
          height: 33rem;
          font-size: 16rem;
          color: #7c7c7c;
          margin-top: 4rem;
        }
      }
      .btn-group {
        margin-top: 40rem;

        .back {
          width: 132rem;
          height: 50rem;
          background: #ffffff;
          border-radius: 25rem;
          border: 1rem solid #32c3f6;
          text-align: center;
          line-height: 50rem;
          font-size: 18rem;
          font-weight: 500;
          color: #2bb3e3;
        }
      }
    }
  }
  .buyContent{
    padding: 40rem 0;
  }
}
</style>
